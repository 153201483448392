module.exports = {
  thumbnailUrl: 'https://statics.web-nari.net/uploads/others/curriculums/',
  list: [
    {
      title: 'it基礎',
      thumbnail: 'it-base.png',
      url: '/curriculums/basis/',
      description:
        'コンピュータやネットワークなどをプログラミングをする上で前提となるIT知識を身に着けることができます。',
      disable: false,
    },
    {
      title: 'Git',
      thumbnail: 'git.png',
      url: '/curriculums/git/',
      description:
        'Gitを用いたソース管理ができるようにGitの概念の説明やGitコマンドの学習、Gitflowなど開発現場でのチーム揮発に必要な知識を解説しています。',
      disable: true,
    },
    {
      title: 'HTML/CSS',
      thumbnail: 'html-css.png',
      url: '/curriculums/html-css/',
      description:
        'Webページの構造を定義するHTML,Webページを装飾するCSSについて解説していきます。',
      disable: true,
    },
    {
      title: 'JavaScript',
      thumbnail: 'javascript.png',
      url: '/curriculums/javascript/',
      description:
        'クライアントサイドプログラミングのためのJavaScriptを学びます。フォームでの入力チェックなど動的なWebページを作るために必要な知識を学びます。',
      disable: true,
    },
    {
      title: 'Linux',
      thumbnail: 'linux.png',
      url: '/curriculums/linux/',
      description:
        'サーバを構築するための基本的なLinuxの知識を身につけます。コマンド操作や環境変数の設定方法などについて学びます。',
      disable: true,
    },
    {
      title: 'MySQL',
      thumbnail: 'mysql.png',
      url: '/curriculums/mysql/',
      description:
        'データーベースについて学んでいきます。SQLの文法、RDBの概念などを解説します。',
      disable: true,
    },
    {
      title: 'Ruby',
      thumbnail: 'ruby.png',
      url: '/curriculums/ruby/',
      description:
        'Ruby言語を用いたプログラミングを学んでいきます。',
      disable: true,
    },
    {
      title: 'Ruby on Rails',
      thumbnail: 'ruby-on-rails.png',
      url: '/curriculums/ruby-on-rails/',
      description:
        'Ruby言語で作られたWebアプリケーションフレームワークについて学んでいきます。',
      disable: true,
    },
  ],
};
