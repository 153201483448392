import React from 'react';
import curriculum from 'config/curriculum';
import Layout from 'components/layouts/Default';
import Card from 'components/molecules/CurriculumCard';

export default class CurriCulumsPage extends React.PureComponent {
  render() {
    const {amp, layout} = this.props.pageContext;
    return (
      <Layout layout={layout}>
        <section className="section curriculum-archive">
          <div className="archive-title">
            <h1 className="title">Webなりカリキュラム</h1>
          </div>
          <div className="archive-body">
            <p className="font-bold">
              WebなりカリキュラムはWebエンジニアになるためのカリキュラムです。
            </p>
            <p>
              webなりでは、Webエンジニアになるためのカリキュラムを無料で公開しています。
              Webエンジニアになるための選択肢の一つとしてWeb系企業への転職が上げられると思いますが転職する際の一つの目安として
            </p>
            <p className="font-bold">
              「自分の力で一からWebアプリケーションを作り上げられる。」
            </p>
            <p>
              というものがあります。
              このカリキュラムではこの目標を達成するための知識を分類して、それぞれの知識を解説しています。
            </p>
            <p>Webエンジニアになるためには大きく分けて6つの技術があります。</p>

            <ul className="required-list">
              <li>
                コンピュータやネットワークなどをプログラミングをする上での
                <strong>IT知識</strong>
              </li>
              <li>
                <strong>Git</strong>を用いたソース管理
              </li>
              <li>
                Webページを構築するための<strong>HTML/CSS</strong>
              </li>
              <li>
                クライアントサイドプログラミングのための
                <strong>JavaScript</strong>
              </li>
              <li>
                サーバを構築するための基本的な<strong>Linux</strong>の知識
              </li>
              <li>
                <strong>MySQL</strong>を用いたデータベース操作
              </li>
              <li>
                <strong>Ruby, PHP</strong>などのサーバサイドプログラミング
              </li>
              <li>
                Webアプリを開発するための<strong>フレームワーク</strong>の知識
              </li>
            </ul>

            <p>
              Webなりカリキュラムではこれらをそれぞれ身につけてWebエンジニアとして最低限
              の知識を身につけられように解説を行なっていきます。
            </p>
          </div>
        </section>
        <section className="section curriculum-archive">
          <div className="archive-title">
            <h2 className="title">カリキュラム目次</h2>
          </div>
          <div className="archive-body">
            <div className="row">
              {curriculum.list.map((item, index) => {
                return (
                  <div className="col s12 m6">
                    <Card item={item} amp={amp} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
