import React from 'react';
import {Link} from 'gatsby';
import Img from 'components/atoms/Image';
import {thumbnailUrl} from 'config/curriculum';

const CurriculumCard = ({item, amp}) => {
  const {title, url, thumbnail, description, disable} = item;
  const _thumbnail = thumbnailUrl + thumbnail;
  return (
    <div className="card curriculum-section hoverable">
      {disable ? (
        <DisabledCard
          thumbnail={_thumbnail}
          title={title}
          description={description}
        />
      ) : (
        <ActiveCard
          amp={amp}
          thumbnail={_thumbnail}
          title={title}
          description={description}
          url={url}
        />
      )}
    </div>
  );
};

const ActiveCard = ({amp, thumbnail, title, description, url}) => {
  return (
    <Link to={url}>
      <div className="card-image">
        <Img amp={amp} src={thumbnail} alt={title} />
      </div>
      <div className="card-content">
        <p>{description}</p>
      </div>
    </Link>
  );
};
const DisabledCard = ({amp, thumbnail, title, description}) => {
  return (
    <>
      <div className="card-image">
        <Img amp={amp} src={thumbnail} alt={title} />
        <div className="mask">
          <p>Coming Soon...</p>
        </div>
      </div>
      <div className="card-content">
        <p>{description}</p>
      </div>
    </>
  );
};

export default CurriculumCard;
